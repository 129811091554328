// Project specific values for the production environment
const config = {
  appId: '1:929467204332:web:2a34a212e8d986600b9bc9',
  apiKey: 'AIzaSyDPhwf9tMorK0Z-1tSaplPNe2nxmfK_1T8',
  authDomain: 'lcpro-9cc17.firebaseapp.com',
  databaseURL: 'https://lcpro-9cc17.firebaseio.com',
  projectId: 'lcpro-9cc17',
  storageBucket: 'lcpro-9cc17.appspot.com',
  messagingSenderId: '929467204332',
  measurementId: 'G-N5P0FXQE0R',
  hotjarId: 2380531
};

config.firestore = {
  // Use custom Firestore host
  host: 'f.liveshopping.bambuser.com',
};

// Where does the player reside? Used when dashboards produces player urls.
config.publicBaseUrl = process.env.WEBPLAYER_BASE_URL || 'https://lcx-player.bambuser.com';

// Where does the embed reside?
config.embedBaseUrl = process.env.EMBED_BASE_URL || `https://lcx-embed.bambuser.com`;

// Where does the widgets reside?
config.widgetsBaseUrl = process.env.WIDGETS_BASE_URL || 'https://lcx-widgets.bambuser.com';

// Where does the app framework reside?
config.appFrameworkBaseUrl = process.env.APP_FRAMEWORK_BASE_URL || `https://app-framework.bambuser.com`;

// Base url for event stream REST API
config.eventStreamBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/eventstream`;

// Base url for metrics REST API
config.metricsBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/collect`;

// Base url for client API
config.clientAPIBaseUrl = process.env.API_BASE_URL || `https://svc-prod-us.liveshopping.bambuser.com/functions/api`;

// Base url for social media API
config.socialMediaBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/functions/socialMedia`;

// Base url for client product config
config.clientProductConfigBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/functions/productConfig`;

// Base url for client Auth
config.clientAuthBaseUrl = process.env.AUTH_BASE_URL || `https://svc-prod-us.liveshopping.bambuser.com/functions/auth`;

// Base url for embed.js inventory res
config.inventoryAPIBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/functions/products`;

// Base url for asset proxy (used when surfing in a srcdoc iframe with minimized player)
config.assetProxyBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/functions/assetProxy`

// Base url for widgets client API
config.widgetsAPIBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/functions/widgets`;

// Base url for App Engine widgets client API
config.widgetsAppEngineAPIBaseUrl = `https://svc-prod-us.liveshopping.bambuser.com/widgets`;

// Base url for vod
config.vodUrl = process.env.VOD_BACKEND_BASE_URL || 'https://vod-j3dgogrltq-uc.a.run.app';

// Return url for redirecting back to the dashboard, for example after
// email verification and sso logout
config.returnUrl = process.env.HOSTING_DASHBOARDURL || 'https://lcx.bambuser.com';

// GTM tag id
config.gtmTagId = 'GTM-PNMBSTP';

// Public stripe key for billing page
config.stripePublicKey = 'pk_live_51IbKuwHvV77QG0tGZ76Vs6kbXWXdbHOh2gsjj6CfL6c509RhIzXbvuwRD2MGInZPN8QCouZa3BwCsNDqhI121stT003bh71P52';

config.facebook = {
  instagramConfigurationId: '354518663590925'
};

// Analytics' GCP-project
config.bigQueryProjectId = 'bambuser-analytics';

// Base url to image transformer service
config.imageTransformerBaseUrl = 'https://cdn.liveshopping.bambuser.com/imageTransformer';

// Base url for client API served via CF
config.clientAPIBaseCloudFrontUrl = 'https://cdn.liveshopping.bambuser.com/api';

// Client API for backoffice
config.backofficeBaseUrl = 'https://svc-prod-us.liveshopping.bambuser.com/functions/backoffice';

// Base url for assets served via CF (fonts, images for customizations etc)
config.assetsCdnUrl = 'https://cdn.liveshopping.bambuser.com';

// Base url for Bambuser public REST API
config.lvsPublicAPIBaseUrl = 'https://liveshopping-api.bambuser.com';

// Base url for One-to-One embed and callWidget
config.callsEmbedUrl = 'https://one-to-one.bambuser.com/embed.js';
config.callsWidgetUrl = 'https://one-to-one.bambuser.com/refresh-us/call-widget';

config.transcriberUrl = 'https://transcribe.bambuser.com';

config.chatDashboardBaseUrl = process.env.CHAT_DASHBOARD_BASE_URL || 'https://us.dashboard.chat.bambuser.com';

module.exports = config;
